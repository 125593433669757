import config from '../config';
import DashboardLayout from '../layouts/DashboardLayout';
import LoginLayout from '../layouts/LoginLayout';
import {
  Home,
  Info,
  Order,
  Login,
  NotifiSim,
  DigiShop,
  CheckPackage,
  About,
  HomeCtv,
  DetailHome,
  Hopmenh,
  HomeDaily,
  Users,
  SimDaily,
  FormatSim,
  SimDigiShop,
  DashboardDigiShop,
  DashboardLogin,
  Dashboard,
  DashboardNotifiSim,
  DashboardAdmin,
  DashboardExcel,
  ThoSim,
  DashboardFlashSale,
  FlashsaleShop,
  DashboardNews,
  News,
  DashboardCN,
  DashboardPT,
  DashboardGiaoDien,
  HomeTwoDetail,
  DataVina,
  DetailShop,
  DashboardShops,
  ShopHome,
  Cart,
} from '../pages';
import { getCookie } from '../utils/helper';

//component pages

const token = getCookie('token');

const publicRoutes = [
  { path: config.routes.home, component: Home },
  { path: config.routes.homeCart, component: Cart },
  { path: config.routes.homeTowShop, component: ShopHome },
  { path: config.routes.homeTowDetail, component: HomeTwoDetail },
  { path: config.routes.goicuocvina, component: DataVina },
  { path: config.routes.homeDetail, component: DetailHome },
  { path: config.routes.homeDetailShop, component: DetailShop },
  { path: config.routes.flashsaleShop, component: FlashsaleShop },
  { path: config.routes.daily, component: HomeDaily },
  { path: config.routes.homeCate, component: Home },
  { path: config.routes.homeMenh, component: Home },
  { path: config.routes.homeFormatSim, component: Home },
  { path: config.routes.homeNewWork, component: Home },
  { path: config.routes.homeRange, component: Home },
  { path: config.routes.homeTranhSo, component: Home },
  { path: config.routes.homeTimKiem, component: Home },
  { path: config.routes.homeStartEnd, component: Home },
  { path: config.routes.homeSearch, component: Home },
  { path: config.routes.homeThuebao, component: Home },
  { path: config.routes.news, component: News },
  { path: config.routes.newsid, component: News },
  { path: config.routes.order, component: Order },
  { path: config.routes.info, component: Info },
  { path: config.routes.hopmenh, component: Hopmenh },
  { path: config.routes.login, component: Login, layout: LoginLayout },
  { path: config.routes.success, component: NotifiSim, layout: LoginLayout },
  { path: config.routes.digi, component: DigiShop, layout: LoginLayout },
  { path: config.routes.about, component: About, layout: LoginLayout },
  { path: config.routes.dashboardLogin, component: DashboardLogin, layout: LoginLayout },
];
const privateRoutes = [
  { path: config.routes.dashboard, component: Dashboard, layout: DashboardLayout },
  { path: config.routes.ctv, component: HomeCtv, layout: DashboardLayout },
  { path: config.routes.dashboardCheckSimDaily, component: SimDaily, layout: DashboardLayout },
  // { path: config.routes.dashboardCheckThoSim, component: ThoSim, layout: DashboardLayout },
  { path: config.routes.dashboardNews, component: DashboardNews, layout: DashboardLayout },
  { path: config.routes.dashboardNotifi, component: DashboardNotifiSim, layout: DashboardLayout },
  { path: config.routes.dashboardAdmin, component: DashboardAdmin, layout: DashboardLayout },
  { path: config.routes.dashboardFlashSale, component: DashboardFlashSale, layout: DashboardLayout },
  { path: config.routes.dashboardExcel, component: DashboardAdmin, layout: DashboardExcel },
  { path: config.routes.dashboardCN, component: DashboardCN, layout: DashboardLayout },
  { path: config.routes.dashboardPT, component: DashboardPT, layout: LoginLayout },
  { path: config.routes.DashboardGiaoDien, component: DashboardGiaoDien, layout: DashboardLayout },
];

const privateRoutes6 = [
  { path: config.routes.dashboard, component: Dashboard, layout: DashboardLayout },
  { path: config.routes.ctv, component: HomeCtv, layout: DashboardLayout },
  { path: config.routes.dashboardCheckSimDaily, component: SimDaily, layout: DashboardLayout },
  // { path: config.routes.dashboardCheckThoSim, component: ThoSim, layout: DashboardLayout },
  { path: config.routes.dashboardNews, component: DashboardNews, layout: DashboardLayout },
  { path: config.routes.dashboardNotifi, component: DashboardNotifiSim, layout: DashboardLayout },
  { path: config.routes.dashboardAdmin, component: DashboardAdmin, layout: DashboardLayout },
  { path: config.routes.dashboardFlashSale, component: DashboardFlashSale, layout: DashboardLayout },
  { path: config.routes.dashboardExcel, component: DashboardAdmin, layout: DashboardExcel },
  { path: config.routes.dashboardCN, component: DashboardCN, layout: DashboardLayout },
  { path: config.routes.dashboardPT, component: DashboardPT, layout: LoginLayout },
  { path: config.routes.DashboardGiaoDien, component: DashboardGiaoDien, layout: DashboardLayout },
  { path: config.routes.checkpackage, component: CheckPackage, layout: LoginLayout },
  { path: config.routes.simdigi, component: SimDigiShop },
  { path: config.routes.dashboardShops, component: DashboardShops, layout: DashboardLayout },
];

const privateRoutes5 = [
  { path: config.routes.dashboard, component: Dashboard, layout: DashboardLayout },
  { path: config.routes.ctv, component: HomeCtv, layout: DashboardLayout },
  { path: config.routes.dashboardCheckSimDaily, component: SimDaily, layout: DashboardLayout },
  { path: config.routes.dashboardCN, component: DashboardCN, layout: DashboardLayout },
];

const privateRoutes4 = [
  { path: config.routes.dashboard, component: Dashboard, layout: DashboardLayout },
  { path: config.routes.ctv, component: HomeCtv, layout: DashboardLayout },
  { path: config.routes.dashboardCheckSimDaily, component: SimDaily, layout: DashboardLayout },
  { path: config.routes.dashboardNews, component: DashboardNews, layout: DashboardLayout },
  { path: config.routes.dashboardNotifi, component: DashboardNotifiSim, layout: DashboardLayout },
  { path: config.routes.dashboardAdmin, component: DashboardAdmin, layout: DashboardLayout },
  { path: config.routes.dashboardFlashSale, component: DashboardFlashSale, layout: DashboardLayout },
  { path: config.routes.dashboardExcel, component: DashboardAdmin, layout: DashboardExcel },
  { path: config.routes.dashboardCN, component: DashboardCN, layout: DashboardLayout },
  { path: config.routes.dashboardPT, component: DashboardPT, layout: LoginLayout },
  { path: config.routes.DashboardGiaoDien, component: DashboardGiaoDien, layout: DashboardLayout },
  { path: config.routes.simdigi, component: SimDigiShop },
];

const privateRoutes3 = [
  { path: config.routes.dashboard, component: Dashboard, layout: DashboardLayout },
  { path: config.routes.ctv, component: HomeCtv, layout: DashboardLayout },
  { path: config.routes.dashboardUsers, component: Users, layout: DashboardLayout },
  { path: config.routes.dashboardCheckSimDaily, component: SimDaily, layout: DashboardLayout },
  { path: config.routes.dashboardNews, component: DashboardNews, layout: DashboardLayout },
  { path: config.routes.dashboardNotifi, component: DashboardNotifiSim, layout: DashboardLayout },
  { path: config.routes.dashboardAdmin, component: DashboardAdmin, layout: DashboardLayout },
  { path: config.routes.dashboardFlashSale, component: DashboardFlashSale, layout: DashboardLayout },
  { path: config.routes.dashboardExcel, component: DashboardAdmin, layout: DashboardExcel },
  { path: config.routes.dashboardCN, component: DashboardCN, layout: DashboardLayout },
  { path: config.routes.dashboardPT, component: DashboardPT, layout: LoginLayout },
  { path: config.routes.DashboardGiaoDien, component: DashboardGiaoDien, layout: DashboardLayout },
  { path: config.routes.checkpackage, component: CheckPackage, layout: LoginLayout },
  { path: config.routes.simdigi, component: SimDigiShop },
  { path: config.routes.dashboardDigishopFormatSim, component: FormatSim, layout: DashboardLayout },
  { path: config.routes.dashboardDigishop, component: DashboardDigiShop, layout: DashboardLayout },
  { path: config.routes.dashboardShops, component: DashboardShops, layout: DashboardLayout },
];
const privateRoutes2 = [
  { path: config.routes.dashboard, component: Dashboard, layout: DashboardLayout },
  { path: config.routes.ctv, component: HomeCtv, layout: DashboardLayout },
  { path: config.routes.dashboardUsers, component: Users, layout: DashboardLayout },
  { path: config.routes.dashboardCheckSimDaily, component: SimDaily, layout: DashboardLayout },
  { path: config.routes.dashboardNews, component: DashboardNews, layout: DashboardLayout },
  { path: config.routes.dashboardNotifi, component: DashboardNotifiSim, layout: DashboardLayout },
  { path: config.routes.dashboardAdmin, component: DashboardAdmin, layout: DashboardLayout },
  { path: config.routes.dashboardFlashSale, component: DashboardFlashSale, layout: DashboardLayout },
  { path: config.routes.dashboardExcel, component: DashboardAdmin, layout: DashboardExcel },
  { path: config.routes.dashboardCN, component: DashboardCN, layout: DashboardLayout },
  { path: config.routes.dashboardPT, component: DashboardPT, layout: LoginLayout },
  { path: config.routes.DashboardGiaoDien, component: DashboardGiaoDien, layout: DashboardLayout },
  { path: config.routes.simdigi, component: SimDigiShop },
];

const privateRoutesAll =
  token &&
  (token?.permission === '6'
    ? privateRoutes6
    : token?.permission === '5'
    ? privateRoutes5
    : token?.permission === '4'
    ? privateRoutes4
    : token?.permission === '3'
    ? privateRoutes3
    : token?.permission === '2'
    ? privateRoutes2
    : privateRoutes);

export { publicRoutes, privateRoutesAll };
