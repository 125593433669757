import { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import { Box, Checkbox, CircularProgress, Container, Grid, Hidden, Stack, TextField } from '@mui/material';

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

import images from '../../assets/images';
import { getSimDigi, getSimDigiALLSize, getSimDigiAll } from '../../services/apiDigiShop';
import { listUserID } from '../../services/apiUsers';
import { ThemeContext } from '../../component/ThemeContext/ThemeContext';

import styles from './digishop.module.scss';
import OrderSim from './orderSim/ordersim';
import { favourite, getCookie, getLocalStorage, numberFormatDeleteDots, numberFormatText } from '../../utils/helper';
import { ButtonUI } from '../../component/CustomerMUI';
const cx = classNames.bind(styles);

function SimDiGiShop() {
  const [timer, setTimer] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const getToken = getCookie('token');
  const hostname = window.location.hostname;
  const loader = useRef(null);
  const [page, setPage] = useState(1);
  const [resultSim, setResultSim] = useState([]);
  const [totalRows, settotalRows] = useState(0);
  const [loading, setLoading] = useState(true);
  const [authDomain, setAuthDomain] = useState({});
  const contextApiUser = useContext(ThemeContext);
  const [open, setOpen] = useState(false);
  const [detailSim, setDetailSim] = useState({});
  const [checkCoppy, setCheckCoppy] = useState({});
  const [iconCopy, setIconCopy] = useState('🍂');
  const [textCopiedSimAndPrice, setTextCopiedSimAndPrice] = useState('');
  const [textCopied, setTextCopied] = useState('');
  const [textCopiedChot, setTextCopiedChot] = useState('');
  const navigate = useNavigate();

  const fetchApiSimDigi = async () => {
    const resultAPISIM = await getSimDigiAll(page);
    if (resultAPISIM && resultAPISIM.totalRows > 0) {
      setResultSim(resultSim.concat(resultAPISIM.data));
      settotalRows(resultAPISIM['totalRows']);
      setLoading(false);
    }
  };

  const fetchApiUserID = async () => {
    const resultAPISIM = await listUserID(hostname);
    if (resultAPISIM.length > 0) {
      setAuthDomain(resultAPISIM[0]);
      contextApiUser.toggleDataUser(resultAPISIM[0]);
    }
  };

  useEffect(() => {
    if (hostname === 'khoso.com.vn' || hostname === 'thegioisimdep.net') {
      console.log('OK');
    } else {
      navigate('/', { replace: true });
    }
    document.title = 'Kho số Vinaphone giá tận gốc';
    const calculateTimeLeft = () => {
      const currentDate = new Date();
      const targetDate = new Date();
      targetDate.setHours(24, 0, 0, 0); // Thiết lập giờ mục tiêu là 0 giờ đêm hôm sau
      const timeLeft = targetDate - currentDate;

      if (timeLeft < 0) {
        // Sale đã kết thúc, trả về giá trị mặc định để đếm ngược xuống 0 từ lúc ban đầu
        setTimer({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
        const seconds = Math.floor((timeLeft / 1000) % 60);

        setTimer({ days, hours, minutes, seconds });
      }
    };

    fetchApiSimDigi();
    fetchApiUserID();
    const timerInterval = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timerInterval);
  }, []);

  useEffect(() => {
    if (page !== 1 && Math.ceil(totalRows / 30) >= page) {
      setLoading(true);
      fetchApiSimDigi();
    }
  }, [page]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) observer.observe(loader.current);

    return () => observer.disconnect();
  }, [totalRows]);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && Math.ceil(totalRows / 30) >= page) {
      setPage((page) => page + 1);
    }
  };

  const handleModalOpen = (sim) => {
    const favoriteSim = favourite(sim.sim.toString()) && favourite(sim.sim.toString())['ynghia'];

    const simDetailDailog = {
      sim: sim.sim_show.toString(),
      price: sim.price.toString(),
      subCard: sim.thuebao,
      netWork: sim.nhamang,
      means: favoriteSim,
      hotline: authDomain.HOTTLINE_SIM,
      GMAIL_NOTIFI: authDomain.GMAIL_NOTIFI,
    };
    // console.log(simDetailDailog);
    setOpen(true);
    setDetailSim(simDetailDailog);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCoppyAll = async (e) => {
    let arrsim = {};
    if (e.target.checked) {
      const dataAll = await getSimDigiALLSize(totalRows);
      if (dataAll.totalRows > 0) {
        dataAll['data']?.map((sim, index) => {
          arrsim[index] = sim;
        });
        handleTextCopiedSimAndPrice(arrsim);
        handleTextCopied(arrsim);
        setCheckCoppy(arrsim);
      }
    } else {
      setCheckCoppy({});
    }
  };

  const handleCoppy = (index, sim) => {
    let state = { ...checkCoppy, [index]: sim };
    setCheckCoppy(state);
    handleTextCopiedSimAndPrice(state);
    handleTextCopied(state);
  };
  const handleDeleteCoppy = (index) => {
    let state = { ...checkCoppy };
    delete state[index];
    setCheckCoppy(state);
    handleTextCopiedSimAndPrice(state);
    handleTextCopied(state);
  };

  const handleTextCopiedSimAndPrice = (state, iconcopy = iconCopy) => {
    let listSim = Object.values(state);
    let arrSim = listSim.map((sim) => {
      let SimsCopied = `${iconcopy?.trim()}${sim.sim_show}`;

      return SimsCopied;
    });
    if (arrSim.length > 0) {
      const re = /,/gi;
      const sims = arrSim.toString().replace(re, '\n');
      setTextCopiedSimAndPrice(sims);
    }
  };
  const handleTextCopied = (state) => {
    let listSim = Object.values(state);
    var simSo = [];
    var simPrice = 0;
    let arrSim = listSim.map((sim) => {
      let SimsCopied = `- Sim: ${sim.sim_show}\n💰 Giá sale: ${numberFormatText(sim.price)} \n👉 Mạng: ${
        sim.nhamang
      } \n- Thuê bao: ${sim.thuebao}\n- Tình trạng: sim còn${sim['note_sim'] ? '\n- ' + sim['note_sim'] : ''} ${
        listSim.length > 1 ? `\n-----------` : ''
      } `;
      simSo.push(`- Sim mua: ${sim.sim_show}\n👉 Mạng: ${sim.nhamang} ${listSim.length > 1 ? `\n-----------` : ''}`);
      simPrice = numberFormatDeleteDots(simPrice) + numberFormatDeleteDots(sim.price);

      return SimsCopied;
    });
    if (arrSim.length > 0) {
      const re = /,/gi;
      const sims = arrSim.toString().replace(re, '\n');
      const simso = simSo.toString().replace(re, '\n');
      let textSimCopied = `KIỂM SIM\n${sims}`;
      let textSimCopiedChot = `✈️𝑻𝒉𝒐̂𝒏𝒈 𝒕𝒊𝒏 𝑪𝒉𝒐̂́𝒕 𝑺𝒊𝒎!!!\n- Tên KH:\n${simso}\n- Liên hệ KH:\n- Địa chỉ Khách: \n ☛ Tổng thu KH : ${numberFormatText(
        simPrice.toString(),
      )}`;
      setTextCopied(textSimCopied);
      setTextCopiedChot(textSimCopiedChot);
    }
  };

  const handleCopySim = (index) => {
    if (index === 1) {
      navigator.clipboard.writeText(textCopiedSimAndPrice);
    } else if (index === 2) {
      navigator.clipboard.writeText(textCopied);
    } else if (index === 3) {
      navigator.clipboard.writeText(textCopiedChot);
    }
    setCheckCoppy({});
    setTextCopiedSimAndPrice('');
    setTextCopied('');
    setTextCopiedChot('');
  };

  const handleCoppyIcon = (e) => {
    handleTextCopiedSimAndPrice(checkCoppy, e.target.value);
  };

  return (
    <>
      {detailSim && <OrderSim openDiaLog={open} onClose={handleClose} detailSim={detailSim} />}
      <Container sx={{ pb: '70px', pl: '10px', pr: '10px' }}>
        <Grid container>
          <Grid item md={12} xs={12} sx={{ mt: 1 }}>
            <img
              src={images.bannerflashsale}
              alt={'vinaphone khuyến mãi'}
              width={'100%'}
              className={cx('bdradius-10')}
            />
          </Grid>
          <Grid item md={12} xs={12} sx={{ mt: 1 }}>
            <Hidden only={['sm', 'xs']}>
              <Stack direction={'row'} spacing={1} justifyContent={'center'} alignItems={'center'}>
                <span>---</span>
                <img src={images.flashsale} alt={'vinaphone flash sale'} width={'115px'} />
                <img src={images.lock} alt={'vinaphone flash sale'} width={'18px'} height={'100%'} />
                <label>KẾT THÚC TRONG</label>
                <p>
                  <b className={cx('cs-countdow')}>
                    {timer.hours.toString().length === 1 ? '0' + timer.hours : timer.hours}
                  </b>{' '}
                  :{' '}
                  <b className={cx('cs-countdow')}>
                    {timer.minutes.toString().length === 1 ? '0' + timer.minutes : timer.minutes}
                  </b>{' '}
                  :{' '}
                  <b className={cx('cs-countdow')}>
                    {timer.seconds.toString().length === 1 ? '0' + timer.seconds : timer.seconds}
                  </b>
                </p>
                <span>---</span>
              </Stack>
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <Stack direction={'row'} spacing={1} justifyContent={'center'} alignItems={'center'} sx={{ mb: 1 }}>
                <img src={images.flashsale} alt={'vinaphone flash sale'} width={'115px'} />
              </Stack>
              <Stack direction={'row'} spacing={1} justifyContent={'center'} alignItems={'center'}>
                <img src={images.lock} alt={'vinaphone flash sale'} width={'18px'} height={'100%'} />
                <label>KẾT THÚC TRONG</label>
                <p>
                  <b className={cx('cs-countdow')}>
                    {timer.hours.toString().length === 1 ? '0' + timer.hours : timer.hours}
                  </b>{' '}
                  :{' '}
                  <b className={cx('cs-countdow')}>
                    {timer.minutes.toString().length === 1 ? '0' + timer.minutes : timer.minutes}
                  </b>{' '}
                  :{' '}
                  <b className={cx('cs-countdow')}>
                    {timer.seconds.toString().length === 1 ? '0' + timer.seconds : timer.seconds}
                  </b>
                </p>
              </Stack>
            </Hidden>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} sx={{ mt: 1, pl: 1 }}>
          <Stack direction={'row'} alignItems={'center'} sx={{ m: 0, p: 0 }}>
            {getToken && (
              <Checkbox
                size="small"
                onClick={handleCoppyAll}
                checked={(checkCoppy ? Object.values(checkCoppy).length : 0) > 0 ? true : false}
              />
            )}
            Tổng sim đang thanh lý: <b> {` ${totalRows} sim`}</b>
          </Stack>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 0.5 }}>
          {/* hiển thị sim */}
          {resultSim.map(
            (item, index) =>
              index % 10 === 0 && (
                <Grid item md={4} xs={12} key={item.id}>
                  <div className={cx('phoi-all')}>
                    <div className={cx('phoi-all-2')}>
                      <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                        <b className={cx('cs-tieude')}>Đồng Giá Sale 499K/s</b>
                      </Stack>
                    </div>
                    <div className={cx('phoi-all-1')}>
                      <b className={cx('fl-left')}>
                        {getToken && (
                          <Checkbox
                            size="small"
                            checked={
                              (checkCoppy[index] ? Object.values(checkCoppy[index]).length : 0) > 0 ? true : false
                            }
                            onClick={() =>
                              (checkCoppy[index] ? Object.values(checkCoppy[index]).length : 0) > 0
                                ? handleDeleteCoppy(index)
                                : handleCoppy(index, item)
                            }
                          />
                        )}
                      </b>
                      <span onClick={() => handleModalOpen(item)}>{item.sim_show}</span>
                      <b className={cx('fl-right')} onClick={() => handleModalOpen(item)}>
                        <CardGiftcardIcon color="error" />
                      </b>
                    </div>
                    {resultSim[index + 1] && (
                      <div className={cx('phoi-all-1')}>
                        <b className={cx('fl-left')}>
                          {getToken && (
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 1] ? Object.values(checkCoppy[index + 1]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 1] ? Object.values(checkCoppy[index + 1]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 1)
                                  : handleCoppy(index + 1, resultSim[index + 1])
                              }
                            />
                          )}
                        </b>
                        <span onClick={() => handleModalOpen(resultSim[index + 1])}>
                          {resultSim[index + 1].sim_show}
                        </span>
                        <b className={cx('fl-right')} onClick={() => handleModalOpen(resultSim[index + 1])}>
                          <CardGiftcardIcon color="error" />
                        </b>
                      </div>
                    )}
                    {resultSim[index + 2] && (
                      <div className={cx('phoi-all-1')}>
                        <b className={cx('fl-left')}>
                          {getToken && (
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 2] ? Object.values(checkCoppy[index + 2]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 2] ? Object.values(checkCoppy[index + 2]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 2)
                                  : handleCoppy(index + 2, resultSim[index + 2])
                              }
                            />
                          )}
                        </b>
                        <span onClick={() => handleModalOpen(resultSim[index + 2])}>
                          {resultSim[index + 2].sim_show}
                        </span>
                        <b className={cx('fl-right')} onClick={() => handleModalOpen(resultSim[index + 2])}>
                          <CardGiftcardIcon color="error" />
                        </b>
                      </div>
                    )}
                    {resultSim[index + 3] && (
                      <div className={cx('phoi-all-1')}>
                        <b className={cx('fl-left')}>
                          {getToken && (
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 3] ? Object.values(checkCoppy[index + 3]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 3] ? Object.values(checkCoppy[index + 3]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 3)
                                  : handleCoppy(index + 3, resultSim[index + 3])
                              }
                            />
                          )}
                        </b>
                        <span onClick={() => handleModalOpen(resultSim[index + 3])}>
                          {resultSim[index + 3].sim_show}
                        </span>
                        <b className={cx('fl-right')} onClick={() => handleModalOpen(resultSim[index + 3])}>
                          <CardGiftcardIcon color="error" />
                        </b>
                      </div>
                    )}
                    {resultSim[index + 4] && (
                      <div className={cx('phoi-all-1')}>
                        <b className={cx('fl-left')}>
                          {getToken && (
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 4] ? Object.values(checkCoppy[index + 4]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 4] ? Object.values(checkCoppy[index + 4]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 4)
                                  : handleCoppy(index + 4, resultSim[index + 4])
                              }
                            />
                          )}
                        </b>
                        <span onClick={() => handleModalOpen(resultSim[index + 4])}>
                          {resultSim[index + 4].sim_show}
                        </span>
                        <b className={cx('fl-right')} onClick={() => handleModalOpen(resultSim[index + 4])}>
                          <CardGiftcardIcon color="error" />
                        </b>
                      </div>
                    )}
                    {resultSim[index + 5] && (
                      <div className={cx('phoi-all-1')}>
                        <b className={cx('fl-left')}>
                          {getToken && (
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 5] ? Object.values(checkCoppy[index + 5]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 5] ? Object.values(checkCoppy[index + 5]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 5)
                                  : handleCoppy(index + 5, resultSim[index + 5])
                              }
                            />
                          )}
                        </b>
                        <span onClick={() => handleModalOpen(resultSim[index + 5])}>
                          {resultSim[index + 5].sim_show}
                        </span>
                        <b className={cx('fl-right')} onClick={() => handleModalOpen(resultSim[index + 5])}>
                          <CardGiftcardIcon color="error" />
                        </b>
                      </div>
                    )}
                    {resultSim[index + 6] && (
                      <div className={cx('phoi-all-1')}>
                        <b className={cx('fl-left')}>
                          {getToken && (
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 6] ? Object.values(checkCoppy[index + 6]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 6] ? Object.values(checkCoppy[index + 6]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 6)
                                  : handleCoppy(index + 6, resultSim[index + 6])
                              }
                            />
                          )}
                        </b>
                        <span onClick={() => handleModalOpen(resultSim[index + 6])}>
                          {resultSim[index + 6].sim_show}
                        </span>
                        <b className={cx('fl-right')} onClick={() => handleModalOpen(resultSim[index + 6])}>
                          <CardGiftcardIcon color="error" />
                        </b>
                      </div>
                    )}
                    {resultSim[index + 7] && (
                      <div className={cx('phoi-all-1')}>
                        <b className={cx('fl-left')}>
                          {getToken && (
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 7] ? Object.values(checkCoppy[index + 7]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 7] ? Object.values(checkCoppy[index + 7]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 7)
                                  : handleCoppy(index + 7, resultSim[index + 7])
                              }
                            />
                          )}
                        </b>
                        <span onClick={() => handleModalOpen(resultSim[index + 7])}>
                          {resultSim[index + 7].sim_show}
                        </span>
                        <b className={cx('fl-right')} onClick={() => handleModalOpen(resultSim[index + 7])}>
                          <CardGiftcardIcon color="error" />
                        </b>
                      </div>
                    )}
                    {resultSim[index + 8] && (
                      <div className={cx('phoi-all-1')}>
                        <b className={cx('fl-left')}>
                          {getToken && (
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 8] ? Object.values(checkCoppy[index + 8]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 8] ? Object.values(checkCoppy[index + 8]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 8)
                                  : handleCoppy(index + 8, resultSim[index + 8])
                              }
                            />
                          )}
                        </b>
                        <span onClick={() => handleModalOpen(resultSim[index + 8])}>
                          {resultSim[index + 8].sim_show}
                        </span>
                        <b className={cx('fl-right')} onClick={() => handleModalOpen(resultSim[index + 8])}>
                          <CardGiftcardIcon color="error" />
                        </b>
                      </div>
                    )}
                    {resultSim[index + 9] && (
                      <div className={cx('phoi-all-1')}>
                        <b className={cx('fl-left')}>
                          {getToken && (
                            <Checkbox
                              size="small"
                              checked={
                                (checkCoppy[index + 9] ? Object.values(checkCoppy[index + 9]).length : 0) > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                (checkCoppy[index + 9] ? Object.values(checkCoppy[index + 9]).length : 0) > 0
                                  ? handleDeleteCoppy(index + 9)
                                  : handleCoppy(index + 9, resultSim[index + 9])
                              }
                            />
                          )}
                        </b>
                        <span onClick={() => handleModalOpen(resultSim[index + 9])}>
                          {resultSim[index + 9].sim_show}
                        </span>
                        <b className={cx('fl-right')} onClick={() => handleModalOpen(resultSim[index + 9])}>
                          <CardGiftcardIcon color="error" />
                        </b>
                      </div>
                    )}
                    <div className={cx('phoi-all-2')}>
                      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <img src={images.phoisimdep} alt={'khoso'} width={'100px'} className={cx('bdradius-10')} />
                        <b className={cx('cs-logo')}>VINAPHONE</b>
                      </Stack>
                    </div>
                    <div className={cx('phoi-all-2')}>
                      <Stack direction={'column'} sx={{ mt: '-12px' }}>
                        <span className={cx('cs-color-white')}> Hỗ trợ đăng ký gói data</span>
                        <span className={cx('cs-color-white')}> Giao sim tận nơi - Đăng ký chính chủ</span>
                        <span className={cx('cs-color-white')}> Tư vấn miễn phí: {authDomain?.HOTTLINE_SIM}</span>
                      </Stack>
                    </div>
                  </div>
                </Grid>
              ),
          )}
        </Grid>

        <div ref={loader}>
          {loading && (
            <div className={cx('css-load-sim')}>
              <CircularProgress color="inherit" size={20} />
              <b className={cx('pl-1')}>Tải dữ liệu...</b>
            </div>
          )}
        </div>
        <Box
          style={{
            position: 'fixed',
            bottom: 360,
            right: 10,
            display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
            zIndex: 10,
          }}
        >
          <TextField
            label="Thêm icon:"
            size="small"
            sx={{ width: '100px', background: '#fff' }}
            variant="outlined"
            value={iconCopy}
            color="error"
            onChange={(e) => {
              setIconCopy(e.target.value);
              handleCoppyIcon(e);
            }}
            focused
          />
        </Box>
        <ButtonUI
          borderBtn="#bef5ff"
          color="info"
          style={{
            position: 'fixed',
            bottom: 320,
            right: 10,
            display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
            zIndex: 10,
          }}
          onClick={() => handleCopySim(1)}
        >
          Copy {Object.values(checkCoppy).length} sim
        </ButtonUI>
        <ButtonUI
          borderBtn="#bef5ff"
          color="info"
          style={{
            position: 'fixed',
            bottom: 280,
            right: 10,
            display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
            zIndex: 10,
          }}
          onClick={() => handleCopySim(2)}
        >
          Copy {Object.values(checkCoppy).length} sim chi tiết
        </ButtonUI>
        <ButtonUI
          borderBtn="#d1fae5"
          color="success"
          style={{
            position: 'fixed',
            bottom: 240,
            right: 10,
            display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
            zIndex: 10,
          }}
          onClick={() => handleCopySim(3)}
        >
          Mẫu đặt sim
        </ButtonUI>
      </Container>
    </>
  );
}

export default SimDiGiShop;
